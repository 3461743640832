<template>
    <div :id="id" :class="className" />
</template>

<script>
// 引入 ECharts 主模块
const echarts = require("echarts/lib/echarts");
// 引入饼状图
require("echarts/lib/chart/line");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
import resize from "./mixins/resize";
export default {
    name: "LineChart",
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: "chart",
        },
        id: {
            type: String,
            default: "chart",
        },
        color: {
            type: String,
            default: "",
        },
        fontColor: {
            type: String,
            default: "",
        },
        lineConfig: {
            type: Object,
            default: () => {},
        },
        lineData: {
            type: Object,
            default: () => {},
        },
        legendShow: {
            type: Boolean,
            default: true,
        },
        grid: {
            type: Object,
            default: () => {},
        },
        title: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        lineData: {
            deep: true,
            handler(val) {
                this.setOptions();
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.initChart();
        });
    },
    beforeDestroy() {
        if (!this.chart) {
            return;
        }
        this.chart.dispose();
        this.chart = null;
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions();
        },
        setOptions() {
            const splitLine = true;
            //console.log(this.lineConfig);
            //console.log(this.lineData);
            this.chart.setOption({
                title: {
                    text: this.title?.text,
                    subtext: this.title?.subtext,
                    left: "center",
                    textStyle: {
                        // color: '#ffffff',
                        fontWeight: "bold",
                        fontSize: 16,
                    },
                    subtextStyle: {
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: 16,
                    },
                },
                tooltip: {
                    trigger: "axis",
                    // formatter: "{a} <br/>{b} : {c}",
					formatter: function(params) {
						console.log(params)
						let str = params[0].seriesName + "<br />" + params[0].marker + params[0].name + ' : ' + params[0].value + '%' + "<br />" + 
						 params[1].seriesName + "<br />" + params[1].marker + params[1].name + ' : ' + params[0].value + '%';
						return str;
					},
                },
                color: ["#00A0E9", "#eb365a"],
                legend: {
                    show: true,
                    data: [this.lineConfig?.name, this.lineConfig?.name2],
                    textStyle: {
                        fontSize: 16,
                    },
                },
                grid: {
                    left: "10px",
                    right: this.grid?.right,
                    bottom: this.grid?.bottom || "1%",
                    top: this.grid?.top || "10%",
                    containLabel: true,
                    show: false,
                },
                xAxis: {
                    type: "category",
                    data: this.lineData?.xData,
                    min: "dataMin",
                    max: "dataMax",
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            // color: '#ffffff'
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: '#2B9DF5',
                            width: 2,
                        },
                        symbol: ["none", "none"],
                    },
                    axisTick: { show: false },
                },
                yAxis: {
                    type: "value",
                    nameTextStyle: {
                        color: "#739AFF",
                        align: "right",
                        padding: [10, 0, 0, 0],
                    },
                    splitLine: {
                        show: splitLine,
                        lineStyle: {
                            type: "dashed",
                            width: 2,
                            color: "#a2a2a2",
                        },
                    },
                    // y轴刻度
                    axisLabel: {
                        // 设置y轴数值为%
                        formatter: "{value} ",
                        interval: "auto",
                        textStyle: {
                            // color: '#eff2fb'
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: '#2B9DF5',
                            width: 2,
                        },
                        symbol: ["none", "none"],
                    },
                    axisTick: { show: false },
                    show: true,
                },
                series: [
                    {
                        name: this.lineConfig?.name,
                        data: this.lineData?.value,
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            normal: {
                                color: "#00A0E9",
                                width: 3,
                            },
                        },
                        areaStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#00A0E9", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#0044f1", // 100% 处的颜色
                                    },
                                ],
                                global: false, // 缺省为 false
                            },
                        },
                    },
                    {
                        name: this.lineConfig?.name2,
                        data: this.lineData?.value2,
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            normal: {
                                color: "#eb365a",
                                width: 3,
                            },
                        },
                        areaStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#eb365a", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#ef1956", // 100% 处的颜色
                                    },
                                ],
                                global: false, // 缺省为 false
                            },
                        },
                    },
                ],
            });
        },
    },
};
</script>

<style scoped></style>
