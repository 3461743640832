<template>
  <div class="zyf-title-outer">
    <div style="font-weight: bold">{{ title }}</div>
    <div class="title-under" />
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.title-under{
  width: inherit;
  height: 2px;
  background-color: #5A8CFF;
}
.zyf-title-outer{
  width: 80px;
  text-align: center;
}
</style>
