<template>
  <el-card :body-style="{padding: 0}">
    <template v-if="icon">
      <div class="flex align-center" style="height: 140px;position: relative;">
        <div>
          <img :src="imgUrl" alt="" style="width: 86px;height: 86px">
        </div>
        <div class="flex flex-column justify-between" style="margin-left: 33px;">
          <div style="margin: 8px 0;">
            <span class="zyf-info-font">{{ name }}</span>
          </div>
          <div style="margin: 4px 0;">
            <div class="flex align-baseline justify-center" :style="statisStyle">
              <span class="zyf-info-font" style="font-size: 32px">{{ count }}</span>
              <span style="font-size: 13px">{{ unit }}</span>
            </div>
          </div>
        </div>
        <div class="img-arrow">
          <img :src="imgArrowUrl" alt="" style="width: 22px;height: 22px;">
        </div>
      </div>
      <!--      <div class="flex justify-flex-end">-->
      <!--        <span style="font-size: 18px;font-weight: bold;cursor: pointer"> >>> </span>-->
      <!--      </div>-->
    </template>
    <template v-else>
      <div class="flex align-center justify-center" style="height: 140px;">
        <img :src="imgUrl" alt="" style="width: 86px;height: 86px">
      </div>
    </template>
  </el-card>
</template>

<script>
export default {
  name: 'ZyfInfo',
  props: {
    name: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    unit: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    },
    imgArrowUrl: {
      type: String,
      default: ''
    },
    statisStyle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

.zyf-info-font {
  font-size: 22px;
  font-weight: bold;
  margin-left: 10px
}

.img-arrow {
  position: absolute;
  bottom: 11px;
  right: 0;
}
</style>
