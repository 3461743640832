<template>
  <div class="mt-2">
    <el-card>
      <el-row>
        <el-col :span="24">
          <vxe-toolbar export :refresh="{query:getFirmQuIndex}">
            <template v-slot:buttons>
              <el-row type="flex" justify="space-between">
                <el-col :span="1">
                  <el-date-picker
                    v-model="condition.date"
                    type="month"
                    class="input searchInput"
                    clearable
                    value-format="yyyy-MM-dd"
                    size="mini"
                    @change="changeTime"
                  />
                </el-col>
              </el-row>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="table"
            border
            resizable
            :auto-resize="true"
            :sync-resize="true"
            :header-row-style="headerStyle"
            :row-style="rowStyle"
            align="center"
            class="vxe-table-element"
            height="560"
            export-config
            :loading="loading"
            :data="table.list"
          >
            <template>
              <vxe-table-column
                field="topicTitle"
                title="题目"
				show-overflow="title"
              />
              <vxe-table-column
                field="topicType"
                title="试题类型"
				show-overflow="title"
              />
			  <vxe-table-column
			    field="highErrorOption"
			    title="易错选项"
				show-overflow="title"
			  />
			  <vxe-table-column
			    field="correctOption"
			    title="正确选项"
				show-overflow="title"
			  />
			  <vxe-table-column
			    field="topicParsing"
			    title="答案解析"
				show-overflow="title"
			  />
              <vxe-table-column
                field="correctRate"
                title="正确率"
              />
            </template>
          </vxe-table>
          <div style="height: 20px" />
          <el-pagination
            background
            :current-page="table.currentPage"
            :page-sizes="[5, 10, 15, 20, 50, 100]"
            :page-size="table.pageSize"
            layout="total, ->, sizes, prev, pager, next, jumper"
            :total="table.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {
  firmQuIndex,
  proportionOfWrongQuestions
} from '@/api/statistics'
import {rowStyle, headerStyle} from '@/utils/tableStyleJs'
import {getMonth, getYear} from "@/utils/getDate";

export default {
  name: 'ViewError',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      condition: {
        date:''
      },
      loading: false,
      table: {
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        sortName: '',
        sortBy: ''
      },
    }
  },
  beforeCreate() {

  },
  created() {
	let dateError = localStorage.getItem('dateError');
	if (dateError) {
		this.condition.date = dateError;
	} else{
		this.condition.date = getYear() + '-' + getMonth()
	}
  },
  mounted() {
    this.getFirmQuIndex()
  },
  methods: {
    rowStyle, headerStyle,
    changeTime() {
      this.getFirmQuIndex()
    },
    getFirmQuIndex() {
      this.$axiosReq(firmQuIndex, null, {
        date: this.condition.date,
        currentPage:this.table.currentPage,
        pageSize:this.table.pageSize
      }, 'get').then(res => {
        this.table.list=res.data.content
        this.table.total = Number(res.data.totalElements)
      })
    },
    handleSizeChange(pageSize) {
      this.table.pageSize = pageSize
      this.table.currentPage = 1
      this.getFirmQuIndex()
    },
    handleCurrentChange(currentPage) {
      this.table.currentPage = currentPage
      this.getFirmQuIndex()
    }
  }
}
</script>

<style scoped>

</style>
