<template>
  <div>
    <ViewError
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @refresh="refreshDialog"
    />
    <el-row :gutter="50" class="mt-2">
      <el-col :span="8">
        <ZyfInfo
          class="zyf-info zyf-info-small"
          :name="condition.area.level==3?'企业数量':'下级行政区域数量'"
          :img-url="require(condition.area.level==3?'@/assets/main/企业数量1.png':'@/assets/main/区域.png')"
          :img-arrow-url="require('@/assets/main/蓝箭头.png')"
          :count="count1"
          :unit="condition.area.level==3?'家':'个'"
          icon="el-icon-milk-tea"
          @click.native="toRoute"
        />
      </el-col>
      <el-col :span="8">
        <ZyfInfo
          class="zyf-info zyf-info-small"
          :name="condition.area.level==3?'学习人数':'企业数量'"
          :img-url="require(condition.area.level==3?'@/assets/main/学习人员.png':'@/assets/main/企业数量.png')"
          :img-arrow-url="require('@/assets/main/紫箭头.png')"
          :count="count2"
          :unit="condition.area.level==3?'人':'家'"
          icon="el-icon-dessert"
          @click.native="toRoute2"
        />
      </el-col>
      <el-col :span="8">
        <ZyfInfo class="zyf-info zyf-info-small" :img-url="require('@/assets/main/添加.png')" />
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt-2">
      <el-col :span="12">
        <el-card style="height: 290px" class="zyf-info">
          <ZyfTitle title="学习统计" />
          <div class="flex justify-around">
            <div class="text-center">
              <CirclePass
                id="d1"
                name=""
                :color="['#41B273', '#f0f0f0']"
                :pop-data="rate1"
                style="flex: 1;width: 200px;height: 190px"
              />
              <div>{{ condition.area.level==3?'企业完成率':'区域完成率' }}</div>
            </div>
            <div class="text-center">
              <CirclePass
                id="d2"
                name=""
                :color="['#E47372', '#f0f0f0']"
                :pop-data="rate2"
                style="flex: 1;width: 200px;height: 190px"
              />
              <div>{{ condition.area.level==3?'学员完成率':'企业完成率' }}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="zyf-info">
          <div class="flex flex-column" style="height: 250px">
            <ZyfTitle title="达标走势" />
            <div class="flex-1 h-100">
              <LineChart
                class="w-100 h-100"
                :line-data="lineData"
                :line-config="lineName"
              />
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt-2">
      <el-col :span="12">
        <el-card style="height: 290px" class="zyf-info">
          <div class="flex justify-between">
            <ZyfTitle title="企业信息" />
            <div>
              <el-input
                v-model="filterName"
                class="input searchInput"
                size="mini"
                suffix-icon="el-icon-search"
                placeholder="搜索"
              />
            </div>
          </div>
          <div class="mt-1">
            <vxe-table
              ref="table"
              border
              resizable
              :auto-resize="true"
              :sync-resize="true"
              :header-row-style="headerStyle"
              :row-style="rowStyle"
              align="center"
              class="vxe-table-element"
              height="220"
              :data="tableList"
            >
              <template v-if="condition.area.level==3">
                <vxe-table-column
                  field="companyName"
                  title="企业名称"
                />
                <vxe-table-column
                  field="traineeNum"
                  title="学员总数"
                />
                <vxe-table-column
                  field="completeTraineeNum"
                  title="达标人数"
                />
                <vxe-table-column
                  field="undoneTraineeNum"
                  title="未达标人数"
                />
                <vxe-table-column
                  field="completionRate"
                  title="完成率"
                >
                  <template v-slot="{row}">
                    <span>{{ row.completionRate }}%</span>
                  </template>
                </vxe-table-column>
              </template>
              <template v-else>
                <vxe-table-column
                  field="areaName"
                  title="区域名称"
                />
                <vxe-table-column
                  field="companyNum"
                  title="企业总数"
                />
                <vxe-table-column
                  field="completeCompanyNum"
                  title="达标企业"
                />
                <vxe-table-column
                  field="undoneCompanyNum"
                  title="未达标企业"
                />
                <vxe-table-column
                  field="completionRate"
                  title="完成率"
                >
                  <template v-slot="{row}">
                    <span>{{ row.completionRate }}%</span>
                  </template>
                </vxe-table-column>
              </template>
            </vxe-table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div class="zyf-info" style="height: 250px">
            <ZyfTitle title="通知公告" />
            <div class="flex-1 flex flex-column justify-between notice-outer" @click="noticeClick">
              <div
                v-for="v in noticeList"
                :key="v.noticeId"
                class="flex justify-between zyf-notice mt-1"
              >
                <div class="flex justify-between align-center" style="font-size: 15px;">
                  <div class="ellipsis-1" style="width: 230px" :title="v.noticeTitle">{{ v.noticeTitle }}</div>
                </div>
                <div class="flex flex-column align-center" style="font-size: 14px;">
                  <div class="flex justify-flex-end">{{ v.num }}人</div>
                  <div>已读人数</div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" v-if="hide">
        <el-card class="zyf-info">
          <div class="flex flex-column" style="height: 250px">
            <div class="flex align-center">
              <img :src="require('@/assets/main/手机 (2).png')" alt="" style="width: 33px;height: 33px">
              <span class="ml-2" style="font-weight: bold">下载中心</span>
            </div>
            <div class="flex-1">
              <el-row :gutter="10" class="flex align-center h-100">
                
                <el-col :span="12">
                  <div class="zyf-down flex-1 flex justify-center align-center">
                    <img :src="require('@/assets/main/二维码.png')" alt="">
                    <el-popover
                      placement="right"
                      width="200"
                      trigger="hover"
                    >
                      <div id="IOS" class="flex justify-center" />
                      <span slot="reference" style="font-size: 20px;font-weight: bold">悟空护驾APP</span>
                    </el-popover>
                    <!--                    <span style="font-size: 20px;font-weight: bold">App Store</span>-->
                  </div>
                  <div class="zyf-down flex-1 flex justify-center align-center mt-2">
                    <a href="http://192.168.88.158:9887/files/悟空护驾APP用户手册.doc" target="_blank">使用手册</a>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ZyfInfo from '@/views/components/ZyfInfo'
import CirclePass from '@/components/Echarts/CirclePass'
import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
import XEUtils from 'xe-utils'
import LineBar from '@/components/Echarts/LineBar'
import {
  companyIndex,
  companyDyIndex
} from '@/api'
import ViewError from '@/views/supervise/CompanyEnd/ViewError'
import QRCode from 'qrcodejs2'
import ZyfTitle from '@/views/components/ZyfTitle'
import LineChart from '@/components/Echarts/LineChart'
export default {
  name: 'Index',
  components: {
    LineChart,
    ViewError,
    ZyfInfo,
    CirclePass,
    ZyfTitle
  },
  props: {
    condition: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      loading: true,
      count: {
        rate: 0
      },
      errRate: 0,
      filterName: '',
      table: {
        list: []
      },
      lineData: {
        value: [],
        value2: [],
        xData: []
      },
      baseInfo: {
        areaCompletionRate: 0,
        areaNum: 0,
        companyCompletionRate: 0,
        companyNum: 0,
        traineeCompletionRate: 0,
        traineeNum: 0
      },
      noticeList: [],
      dialog: {
        row: Object,
        info: {},
        refresh: false, // 每次刷新DOM
        show: false
      },
	  hide: false
    }
  },
  computed: {
    tableList(v) {
      const filterName = XEUtils.toString(this.filterName).trim().toLowerCase()
      if (filterName) {
        const filterRE = new RegExp(filterName, 'gi')
        const searchProps = this.condition.area.level == 3 ? ['companyName'] : ['areaName']
        const rest = this.table.list.filter(item => searchProps.some(key => XEUtils.toString(item[key]).toLowerCase().indexOf(filterName) > -1))
        return rest.map(row => {
          const item = Object.assign({}, row)
          searchProps.forEach(key => {
            item[key] = XEUtils.toString(item[key]).replace(filterRE, match => `${match}`)
          })
          return item
        })
      }
      return this.table.list
    },
    count1() {
      return this.condition.area.level == 3 ? this.baseInfo.companyNum : this.baseInfo.areaNum
    },
    count2() {
      return this.condition.area.level == 3 ? this.baseInfo.traineeNum : this.baseInfo.companyNum
    },
    rate1() {
      return this.condition.area.level == 3 ? this.baseInfo.companyCompletionRate : this.baseInfo.areaCompletionRate
    },
    rate2() {
      return this.condition.area.level == 3 ? this.baseInfo.traineeCompletionRate : this.baseInfo.companyCompletionRate
    },
    lineName() {
      const arr = { name: '区域', name2: '企业' }
      const arr2 = { name: '企业', name2: '学员' }
      return this.condition.area.level == 3 ? arr2 : arr
    }
  },
  watch: {
    condition: {
      handler(v) {
        this.changeCondition()
      },
      deep: true
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    rowStyle, headerStyle,
    changeCondition() {
      this.getCompanyIndex()
      this.getCompanyDyIndex()
    },
    getCompanyIndex() {
      this.$axiosReq(companyIndex, null, {
        pageSize: 10000
      }, 'get').then(res => {
        this.noticeList = res.data.content
      })
    },
    getCompanyDyIndex() {
      this.$axiosReq(companyDyIndex, null, {
        date: this.condition.date,
        areaCode: this.condition.areaCode,
        pageSize: 10000
      }, 'get').then(res => {
        this.baseInfo.areaCompletionRate = res.data.areaCompletionRate
        this.baseInfo.areaNum = res.data.areaNum
        this.baseInfo.companyCompletionRate = res.data.companyCompletionRate
        this.baseInfo.companyNum = res.data.companyNum
        this.baseInfo.traineeCompletionRate = res.data.traineeCompletionRate
        this.baseInfo.traineeNum = res.data.traineeNum
        this.table.list = res.data.pageVo.content
        this.lineData.xData = []
        this.lineData.value = []
        this.lineData.value2 = []
        
		if (this.condition.area.level == 3) {
			for (const i of res.data.companyUserChartVos) {
			  this.lineData.xData.push(i.month)
			  this.lineData.value.push(i.companyRate)
			  this.lineData.value2.push(i.userRate)
			}
		} else {
			for (const i of res.data.companyUserChartVos) {
			  this.lineData.xData.push(i.month)
			  this.lineData.value2.push(i.companyRate)
			  this.lineData.value.push(i.userRate)
			}
		}
      })
    },
    toNotice() {
      this.$router.push('/businessCnf/notice')
    },
    toRoute() {
      if (this.condition.area.level == 3) {
        this.$router.push('/organize/company')
      } else {
        this.$router.push('/safeTrain/safeTrain')
      }
    },
	noticeClick() {
	  this.$router.push('/infoManage/notice')
	},
    toRoute2() {
      if (this.condition.area.level == 3) {
        this.$router.push('/safeTrain/safeTrain')
      } else {
        this.$router.push('/organize/company')
      }
    },
    // toCarManage() {
    //   this.$router.push('/manageCenter/manageCar')
    // },
    // toCompanySearch() {
    //   this.$router.push('/statistics/safeTrain')
    // },
    refreshDialog() {
      this.dialog.refresh = false
    }
  }
}
</script>

<style scoped>
.zyf-info {
  padding: 0 40px;
}
.zyf-info-small{
  height: 145px;border-radius: 20px;

}
::v-deep.zyf-info-small .el-card__body{
  padding-left: 40px !important;
}
.notice-outer{
  overflow-y: auto;
  height: 100%;
  padding-right: 10px;
}
.notice-outer::-webkit-scrollbar-thumb{
  width: 2px;
  background-color: #606266;
}
.notice-outer::-webkit-scrollbar{
  width: 2px;
  background-color: #ffffff;
}
.zyf-notice {
  font-weight: bold;
  height: 50px;
  border-bottom: 1px solid #afd0ff;
  cursor: pointer;
}

.zyf-down {
  /*width: 200px;*/
  border-radius: 10px;
  height: 60px;
  border: 1px solid #979ca8;
}
</style>
