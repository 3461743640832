<template>
    <div class="dashboard-container">
        <el-card>
            <el-row type="flex" justify="between">
                <el-col v-if="main == 0">
                    <div class="flex">
                        <div>
                            <el-date-picker
                                v-model="condition.date"
                                type="month"
                                class="input searchInput"
                                value-format="yyyy-MM"
                                size="mini"
                                @change="changeDate"
                            />
                        </div>
                        <div class="ml-1">
                            <el-cascader
                                ref="region"
                                v-model="condition.areaCode"
                                :placeholder="placeholder"
                                filterable
                                class="input searchInput"
                                :options="region"
                                size="mini"
                                :props="{
                                    lazy: $admin,
                                    lazyLoad: lazyRegion,
                                    value: 'areaCode',
                                    children: 'children',
                                    label: 'areaName',
                                    checkStrictly: true,
                                    expandTrigger: 'hover',
                                    emitPath: false,
                                }"
                                @change="changeRegion"
                            />
                        </div>
                        <div class="ml-1">
                            <el-button
                                type="primary"
                                size="mini"
                                @click="refresh"
                                >刷新</el-button
                            >
                        </div>
                    </div>
                </el-col>
                <el-col v-else>
                    <el-button type="primary" size="mini" @click="returnMain"
                        >返回</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <template v-if="$companyType == 0">
            <Company ref="company" :condition="condition" />
        </template>
        <template v-else>
            <CompanyEnd ref="companySelf" :condition="condition" />
        </template>
    </div>
</template>

<script>
import { getAreaChildren, getAreaTreeByCode } from "@/api/region";
import { getYear, getMonth } from "@/utils/getDate";
import Company from "./Company";
import CompanyEnd from "./CompanyEnd";
import { mapGetters } from "vuex";
export default {
    name: "Index",
    components: {
        Company,
        CompanyEnd,
    },
    data() {
        return {
            condition: {
                areaCode: "",
                date: "",
                area: {},
            },
            region: [],
            tab: "company",
            count: 0,
            placeholder: "请选择",
        };
    },
    computed: {
        ...mapGetters(["main"]),
    },
    watch: {
        isError(v) {
            this.isError = v;
        },
    },
    created() {
        this.condition.date = getYear() + "-" + getMonth();
        if (!this.$admin) this.getAreaTreeByCode();
    },
    mounted() {
        this.placeholder = localStorage.getItem("cityNameAll");
        if (this.placeholder) {
            this.placeholder = this.placeholder
                .replace('"', "")
                .replace('"', "");
        }
    },
    methods: {
        getAreaTreeByCode() {
            this.loading = true;
            this.$axiosReq(
                getAreaTreeByCode + this.$regionCode,
                null,
                null,
                "get"
            )
                .then((res) => {
                    var isArr = Array.isArray(res.data);
                    if (isArr) {
                        this.region = res.data;
                    } else {
                        this.region = [res.data];
                    }
                    this.condition.areaCode = this.region.areaCode;
                    this.condition.area = this.region;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        lazyRegion(v, resolve) {
            // 异步加载子节点
            this.$axiosReq(
                getAreaChildren,
                null,
                {
                    parentId: v.data?.id,
                },
                "get"
            ).then((res) => {
                const arr = [];
                for (const i of res.data) {
                    arr.push({
                        ...i,
                        hasChild: i.level < 3,
                    });
                }
                if (this.count === 0) {
                    this.condition.areaCode = sessionStorage.RegionCode;
                }
                this.count++;
                resolve(arr);
            });
        },
        changeTab(v) {
            if (v === "company") {
                this.$refs.company.changeCondition();
            } else {
                this.$refs.driver.changeCondition();
            }
        },
        changeRegion(v) {
            var dataAll = this.region;
            this.condition.areaCode = v;
            if (!this.$admin) {
                for (let i = 0; i < dataAll.length; i++) {
                    if (dataAll[i].areaCode == v) {
                        this.condition.area = dataAll[i];
                        return false;
                    } else {
                        if (dataAll[i].children) {
                            for (
                                let f = 0;
                                f < dataAll[i].children.length;
                                f++
                            ) {
                                if (dataAll[i].children[f].areaCode == v) {
                                    this.condition.area =
                                        dataAll[i].children[f];
                                    return false;
                                } else {
                                    if (dataAll[i].children[f].children) {
                                        for (
                                            let e = 0;
                                            e <
                                            dataAll[i].children[e].children
                                                .length;
                                            e++
                                        ) {
                                            if (
                                                dataAll[i].children[f].children[
                                                    e
                                                ].areaCode == v
                                            ) {
                                                this.condition.area =
                                                    dataAll[i].children[
                                                        f
                                                    ].children[e];
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                this.condition.area = this.$refs.region.getCheckedNodes()[0];
            }
        },
        changeDate() {},
        refresh() {
			this.condition.area.level = 1;
            this.condition.areaCode = this.$regionCode;
            if (this.$companyType == 0) {
                this.$refs.company.changeCondition();
            } else {
                this.$refs.companySelf.changeCondition();
            }
        },
        returnMain() {
            this.$store.dispatch("master/toggleMain", 0);
        },
    },
};
</script>

<style scoped>
.dashboard-container {
    height: calc(100vh - 150px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.dashboard-container::-webkit-scrollbar {
    display: none;
}
</style>
