<template>
  <div
    :id="id"
    :class="className"
  />
</template>

<script>
  // 引入 ECharts 主模块
  const echarts = require('echarts/lib/echarts')
  // 引入饼状图
  require('echarts/lib/chart/pie')
  // 引入提示框和标题组件
  require('echarts/lib/component/title')
  import resize from './mixins/resize'
    export default {
      name: 'CirclePass',
      mixins: [resize],
      props: {
        className: {
          type: String,
          default: 'chart'
        },
        id: {
          type: String,
          default: 'chart'
        },
        color: '',
        fontColor: {
          type: String,
          default: ''
        },
        name: {
          type: String,
          default: ''
        },
        popData: '',
        legendShow: {
          type: Boolean,
          default: true
        },
        grid: {
          type: Object,
          default: () => {}
        },
        title: {
          type: Object,
          default: () => {}
        }
      },
      data() {
        return {
          chart: null
        }
      },
      watch: {
        popData: {
          deep: true,
          handler(val) {
            this.setOptions(val)
          }
        }
      },
      mounted() {
        this.$nextTick(() => {
          this.initChart()
        })
      },
      beforeDestroy() {
        if (!this.chart) {
          return
        }
        this.chart.dispose()
        this.chart = null
      },
      methods: {
        initChart() {
          this.chart = echarts.init(document.getElementById(this.id))
          this.setOptions(this.popData)
        },
        setOptions(data) {
          this.chart.setOption({
            backgroundColor: this.backgroundColor,
            color: this.color,
            title: {
              text: this.title,
              top: '3%',
              left: '1%',
              textStyle: {
                color: '#333',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontFamily: 'sans-serif',
                fontSize: 16
              }
            },
            series: [{
              name: '来源',
              type: 'pie',
              radius: ['60%', '75%'],
              avoidLabelOverlap: false,
              hoverAnimation: false,
              label: {
                normal: {
                  show: false,
                  position: 'center',
                  textStyle: {
                    fontSize: this.fontSize,
                    fontWeight: 'bold'
                  },
                  formatter: '{b}\n{c}%'
                }
              },
              data: [{
                value: this.popData,
                name: this.name,
                label: {
                  normal: {
                    show: true
                  }
                }
              },
                {
                  value: 100 - this.popData,
                  name: ''
                }
              ]
            }]
          })
        }
      }
    }
</script>

<style scoped>

</style>
