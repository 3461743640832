<template>
  <div
    :id="id"
    :class="className"
  />
</template>

<script>
// 引入 ECharts 主模块
const echarts = require('echarts/lib/echarts')
// 引入饼状图
require('echarts/lib/chart/line')
// 引入提示框和标题组件
require('echarts/lib/component/tooltip')
import resize from './mixins/resize'
export default {
  name: 'LineChart',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    color: {
      type: String,
      default: ''
    },
    fontColor: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    lineData: {
      type: Object,
      default: () => {}
    },
    legendShow: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Object,
      default: () => {}
    },
    title: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    lineData: {
      deep: true,
      handler(val) {
        this.setOptions()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id))
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter: '{a} <br/>{b} : {c}'
        },
        grid: {
          left: '10px',
          right: this.grid?.right,
          bottom: this.grid?.bottom || '1%',
          top: this.grid?.top || '10%',
          containLabel: true,
          show: false
        },
        // color: ['#3fcb1d', '#eb365a'],
        // grid: {
        //   left: '10px',
        //   right: this.grid?.right,
        //   bottom: this.grid?.bottom || '1%',
        //   top: this.grid?.top || '10%',
        //   containLabel: true,
        //   show: false
        // },
        xAxis: {
          type: 'category',
          data: this.lineData?.xData,
          min: 'dataMin',
          max: 'dataMax',
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#333333'
            }
          },
          axisLine: { show: true, lineStyle: {
            color: '#E6E6E6',
            width: 2
          }, symbol: ['none', 'none'] },
          axisTick: { show: false }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100,
          splitNumber: 5,
          nameTextStyle: {
            color: '#739AFF',
            align: 'right',
            padding: [10, 0, 0, 0]
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              width: 2,
              color: '#a2a2a2'
            }
          },
          // y轴刻度
          axisLabel: {
            // 设置y轴数值为%
            formatter: '{value} %',
            interval: 'auto',
            textStyle: {
              color: '#333333'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E6E6E6',
              width: 2
            },
            symbol: ['none', 'none']
          },
          axisTick: { show: false },
          show: true
        },
        series: [{
          name: '达标率',
          data: this.lineData?.value,
          type: 'line',
          smooth: true,
          symbolSize: 3,
          lineStyle: {
            normal: {
              color: '#00A0E9',
              width: 4
            }
          },
          itemStyle: {
            color: '#00A0E9',
            borderColor: '#00A0E9',
            borderWidth: 5
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#00A0E9' // 0% 处的颜色
              }, {
                offset: 1, color: '#E6E6E6' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }]
      })
    }
  }
}
</script>

<style scoped>

</style>
