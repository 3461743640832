<template>
	<div>
		<transition name="fade-transform">
			<div v-if="main==0">
				<el-row :gutter="20" class="mt-2">
					<el-col :span="6">
						<ZyfInfo class="zyf-info" name="企业人员" :img-url="require('@/assets/main/企业人员.png')" :img-arrow-url="require('@/assets/main/蓝箭头.png')"
						 :count="baseInfo.userNum" unit="人" icon="el-icon-user" statis-style="color:#70B4FB" style="border-radius: 24px;"
						 @click.native="toPerson" />
					</el-col>
					<el-col :span="6">
						<ZyfInfo class="zyf-info" name="企业车辆" :img-url="require('@/assets/main/企业车辆.png')" :img-arrow-url="require('@/assets/main/紫箭头.png')"
						 :count="baseInfo.carNum" unit="辆" icon="el-icon-milk-tea" statis-style="color:#717FEE" style="border-radius: 24px;"
						 @click.native="toCarManage" />
					</el-col>
					<el-col :span="6">
						<ZyfInfo class="zyf-info" name="学习完成人数" :img-url="require('@/assets/main/学习完成人数.png')" :img-arrow-url="require('@/assets/main/红箭头.png')"
						 :count="baseInfo.completeTraineeNum" unit="人" icon="el-icon-dessert" statis-style="color:#BC8BEC" style="border-radius: 24px;"
						 @click.native="toCompanySearch" />
					</el-col>
					<el-col :span="6">
						<ZyfInfo v-if="!$hasBtn( 'sys:permissions:report:health:warning' )" class="zyf-info" style="border-radius: 24px;" :img-url="require('@/assets/main/添加.png')" />
						<div @click="toJlyj" v-if="$hasBtn( 'sys:permissions:report:health:warning' )" class="jkjc-info">
							<div class="flex align-center justify-center" style="height: 140px;position: relative;width: 100%;">
							  <div>
							    <img src="@/assets/main/健康预警.png" style="width: 86px;height: 86px">
							  </div>
							  <div class="flex flex-column justify-between" style="margin-left: 33px;">
							    <div style="margin: 8px 0;">
							      <span class="zyf-info-font">监测人数</span>
							    </div>
							    <div style="margin: 4px 0;">
							      <div class="flex align-baseline justify-center" style="color: #ffaa44;">
							        <span class="zyf-info-font" style="font-size: 32px">{{monitorNum ? monitorNum : 0}}</span>
							        <span style="font-size: 13px">人</span>
							      </div>
							    </div>
							  </div>
							  <div class="flex flex-column justify-between" style="margin-left: 33px;">
							    <div style="margin: 8px 0;">
							      <span class="zyf-info-font">健康预警</span>
							    </div>
							    <div style="margin: 4px 0;">
							      <div class="flex align-baseline justify-center" style="color: #ffaa44;">
							        <span class="zyf-info-font" style="font-size: 32px">{{healthWarningNum ? healthWarningNum : 0}}</span>
							        <span style="font-size: 13px">次</span>
							      </div>
							    </div>
							  </div>
							  <div class="img-arrow">
							    <img src="@/assets/main/红箭头.png" alt="" style="width: 22px;height: 22px;">
							  </div>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="20" class="mt-2">
					<el-col :span="12">
						<ZyfCard style="height: 290px" title="学习统计">
							<template v-slot:content>
								<div class="flex justify-around">
									<div class="text-center">
										<CirclePass id="d1" name="" :color="['#70B4FB', '#f0f0f0']" :pop-data="rate.completionRate" style="flex: 1;width: 200px;height: 190px" />
										<div>课程完成率</div>
									</div>
									<div class="text-center" @click="openError">
										<CirclePass id="d2" name="" :color="['#FD8571', '#f0f0f0']" :pop-data="rate.errorPercentage" style="flex: 1;width: 200px;height: 190px" />
										<div>高频错题占比 <span style="
font-size: 14px;
font-weight: 400;
color: #477EDF;cursor: pointer">查看</span></div>
									</div>
								</div>
							</template>
						</ZyfCard>
					</el-col>
					<el-col :span="12">
						<ZyfCard style="height: 290px" title="未完成学员">
							<template v-slot:header>
								<div class="flex justify-between">
									<div style="width: 107px;text-align: center">
										<span style="font-weight: bold">未完成学员</span>
										<div style="width: 100%;
height: 4px;
background: #88C1FC;" />
									</div>
									<div>
										<el-input v-model="filterName" class="input searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索"
										 @input="getFirmTraIndex" />
									</div>
								</div>
							</template>
							<template v-slot:content>
								<div class="mt-1">
									<vxe-table ref="table" border resizable :auto-resize="true" :sync-resize="true" :header-row-style="headerStyle"
									 :row-style="rowStyle" align="center" class="vxe-table-element" height="220" :loading="loading" :data="table.list">
										<template>
											<vxe-table-column field="realName" title="学员名称" />
											<vxe-table-column field="phone" title="手机号码" />
											<vxe-table-column field="departName" title="部门" />
										</template>
									</vxe-table>
								</div>
							</template>
						</ZyfCard>
					</el-col>
				</el-row>
				<el-row :gutter="20" class="mt-2">
					<el-col :span="12">
						<ZyfCard style="height: 290px" title="达标走势">
							<template v-slot:content>
								<div class="flex-1 w-100 h-100">
									<LineArea class="w-100 h-100" :line-data="lineData" />
								</div>
							</template>
						</ZyfCard>
					</el-col>
					<el-col :span="6">
						<ZyfCard style="height: 290px" title="通知公告">
							<template v-slot:content>
								<div class="flex-1 flex flex-column justify-between notice-outer" @click="noticeClick">
									<div v-for="v in noticeList" :key="v.notice_id" class="flex justify-center flex-column zyf-notice">
										<div class="flex justify-between" style="font-size: 13px;">
											<div class="ellipsis-1" style="width: 210px" :title="v.noticeTitle">{{ v.noticeTitle }}</div>
											<div>已读人数</div>
										</div>
										<div class="flex justify-end" style="font-size: 13px;">{{ v.num }}人</div>
									</div>
								</div>
							</template>
						</ZyfCard>
					</el-col>
					<el-col :span="6">
						<ZyfCard style="height: 290px" title="下载中心">
							<template v-slot:content>
								<div class="flex flex-column" style="margin-top: 50px;">
									<div class="flex-1">
										<el-row :gutter="10" class="flex align-center h-100">

											<el-col :span="12" class="flex-1 flex flex-column justify-center align-center">
												<div>
													<el-popover placement="top-start" width="200" trigger="hover">
														<div class="flex justify-center">
															<img :src="$fileUrl+qrCodeUrl" alt="" style="width: 300px;height: 600px">
														</div>
														<img slot="reference" :src="require('@/assets/main/二维码app.png')" alt="">
													</el-popover>
												</div>
												<div class="zyf-down flex justify-center align-center mt-2">
													<a :href="$fileUrl+manualUrl" target="_blank">使用手册</a>
												</div>
											</el-col>
										</el-row>
									</div>
								</div>
							</template>
						</ZyfCard>
					</el-col>
				</el-row>
			</div>
		</transition>
		<transition name="fade-transform">
			<ViewError v-if="main==1" :row="dialog.row" :info="dialog.info" @refresh="refreshDialog" />
		</transition>
	</div>
</template>

<script>
	import ZyfInfo from '@/views/components/ZyfInfo'
	import ZyfCard from '@/views/components/ZyfCard'
	import CirclePass from '@/components/Echarts/CirclePass'
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import XEUtils from 'xe-utils'
	import LineArea from '@/components/Echarts/LineArea'
	import {
		firmStaIndex,
		firmIndex,
		firmQuIndex,
		firmTraIndex
	} from '@/api/statistics'

	import ViewError from '@/views/supervise/CompanyEnd/ViewError'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'Index',
		components: {
			ViewError,
			ZyfInfo,
			CirclePass,
			ZyfCard,
			LineArea
		},
		props: {
			condition: {
				type: Object,
				Object: () => {}
			}
		},
		data() {
			return {
				loading: false,
				filterName: '',
				table: {
					list: []
				},
				lineData: {
					value: [],
					xData: []
				},
				baseInfo: {
					completeTraineeNum: 0,
					carNum: 0,
					userNum: 0
				},
				rate: {
					completionRate: 0,
					errorPercentage: 0
				},
				noticeList: [],
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false
				},
				manualUrl: '',
				qrCodeUrl: '',
				androidCode: 'https://zdwy.online:5159/files/download/wkhj.1.6.0.apk',
				healthWarningNum: '',
				monitorNum: '',
			}
		},
		computed: {
			tableList(v) {
				const filterName = XEUtils.toString(this.filterName).trim().toLowerCase()
				if (filterName) {
					const filterRE = new RegExp(filterName, 'gi')
					const searchProps = ['user_name']
					const rest = this.table.list.filter(item => searchProps.some(key => XEUtils.toString(item[key]).toLowerCase().indexOf(
						filterName) > -1))
					return rest.map(row => {
						const item = Object.assign({}, row)
						searchProps.forEach(key => {
							item[key] = XEUtils.toString(item[key]).replace(filterRE, match => `${match}`)
						})
						return item
					})
				}
				return this.table.list
			},
			...mapGetters([
				'main'
			])
		},
		watch: {
			condition: {
				handler(v) {
					this.changeCondition()
				},
				deep: true
			}
		},
		created() {},
		mounted() {},
		methods: {
			rowStyle,
			headerStyle,
			changeCondition() {
				this.getFirmIndex()
				this.getFirmStaIndex()
				this.getFirmTraIndex()
				this.getFirmHealth()
			},
			getFirmStaIndex() {
				this.$axiosReq(firmStaIndex, null, {
					date: this.condition.date
				}, 'get').then(res => {
					this.baseInfo.completeTraineeNum = res.data.completeTraineeNum
					this.rate.completionRate = res.data.completionRate
					this.rate.errorPercentage = res.data.errorPercentage
				})
			},
			getFirmIndex() {
				this.$axiosReq(firmIndex, null, {
					pageSize: 100000
				}, 'get').then(res => {
					this.baseInfo.carNum = res.data.carNum
					this.baseInfo.userNum = res.data.userNum
					this.qrCodeUrl = res.data.qrCodeUrl
					this.manualUrl = res.data.manualUrl
					this.noticeList = res.data.pageVo.content
					this.lineData.xData = []
					this.lineData.value = []
					for (const i of res.data.studyTrendVos) {
						this.lineData.xData.push(i.month)
						this.lineData.value.push(i.complianceRate)
					}
				})
			},
			getFirmTraIndex() {
				this.loading = true
				this.$axiosReq(firmTraIndex, null, {
					date: this.condition.date,
					selectField: this.filterName,
					pageSize: 10000
				}, 'get').then(res => {
					this.table.list = res.data.content
				}).finally(() => {
					this.loading = false
				})
			},
			getFirmHealth() {
				this.loading = true
				this.$axiosReq('/course/server/escort/web/statistics/firm/health', null, {
					createTime: this.condition.date + '-01 00:00:00',
					companyId: this.$companyId,
				}, 'get').then(res => {
					this.healthWarningNum = res.data.healthWarningNum;
					this.monitorNum = res.data.monitorNum;
				}).finally(() => {
					this.loading = false
				})
			},
			toNotice() {
				// this.$router.push('/statistics/noticeSta')
			},
			noticeClick() {
				this.$router.push('/infoManage/notice')
			},
			toPerson() {
				this.$router.push('/organize/persion')
			},
			toCarManage() {
				this.$router.push('/organize/manageCar')
			},
			toCompanySearch() {
				this.$router.push('/safeTrain/safeTrain')
			},
			toJlyj() {
				this.$router.push('/safeTrain/healthWarning')
			},
			toError() {
				this.dialog.refresh = true
			},
			openError() {
				localStorage.setItem('dateError', this.condition.date);
				this.$store.dispatch('master/toggleMain', 1)
			},
			refreshDialog() {
				this.dialog.refresh = false
			}
		}
	}
</script>

<style scoped>
	.zyf-info {
		padding: 0 40px;
	}

	.zyf-notice {
		font-size: 16px;
		font-weight: bold;
		height: 80px;
		border-bottom: 1px solid #afd0ff;
		cursor: pointer;
	}

	.zyf-down {
		/*width: 200px;*/
		border-radius: 35px;
		height: 35px;
		width: 124px;
		border: 2px solid #00A0E9;
		font-size: 16px;
	}

	::v-deep .input input {
		border-radius: 28px;
	}

	::v-deep .vxe-table.border--full .vxe-body--column,
	::v-deep .vxe-table.border--full .vxe-header--column {
		background-image: unset;
	}

	::v-deep .vxe-table .vxe-table--header-wrapper .vxe-table--header-border-line {
		border-bottom: none;
	}

	::v-deep .vxe-table .vxe-table--border-line {
		border: none;
	}

	.notice-outer {
		overflow-y: auto;
		height: 100%;
	}

	.notice-outer::-webkit-scrollbar-thumb {
		width: 2px;
		background-color: #606266;
	}

	.notice-outer::-webkit-scrollbar {
		width: 2px;
		background-color: #ffffff;
	}

	.jkjc-info {
		width: 100%;
		border-radius: 20px;
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
	
	.zyf-info-font {
	  font-size: 22px;
	  font-weight: bold;
	  margin-left: 10px
	}
	
	.img-arrow {
	  position: absolute;
	  bottom: 11px;
	  right: 0;
	}
</style>
