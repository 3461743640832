<template>
  <el-card class="zyf-info h-100">
    <div v-if="!$slots.header">
      <div style="width: 86px;text-align: center">
        <span style="font-weight: bold">{{title}}</span>
        <div style="width: 86px;
height: 4px;
background: #88C1FC;"></div>
      </div>
    </div>
    <slot v-else name="header"></slot>
    <slot name="content"></slot>
  </el-card>
</template>

<script>
export default {
  name: "ZyfCard",
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
::v-deep .el-card__body{
  height: 90%;
}
</style>
