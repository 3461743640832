<template>
    <div :id="id" :class="className" />
</template>

<script>
// 引入 ECharts 主模块
const echarts = require("echarts/lib/echarts");
// 引入饼状图
require("echarts/lib/chart/bar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/markLine");
import resize from "./mixins/resize";
export default {
    name: "LineBar",
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: "chart",
        },
        id: {
            type: String,
            default: "chart",
        },
        width: {
            type: String,
            default: "200px",
        },
        height: {
            type: String,
            default: "200px",
        },
        color: {
            type: String,
            default: "",
        },
        fontColor: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        lineData: {
            type: Object,
            default: () => {},
        },
        legendShow: {
            type: Boolean,
            default: true,
        },
        grid: {
            type: Object,
            default: () => {},
        },
        title: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        lineData: {
            deep: true,
            handler(v) {
                //console.log(v);
                this.setOptions();
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.initChart();
        });
    },
    beforeDestroy() {
        if (!this.chart) {
            return;
        }
        this.chart.dispose();
        this.chart = null;
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions();
        },
        setOptions() {
            const splitLine = true;
            this.chart.setOption({
                title: {
                    text: this.title?.text,
                    subtext: this.title?.subtext,
                    left: "center",
                    textStyle: {
                        // color: '#ffffff',
                        fontWeight: "bold",
                        fontSize: 16,
                    },
                    subtextStyle: {
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: 16,
                    },
                },
                tooltip: {
                    trigger: "axis",
                    formatter: "{a} <br/>{b} : {c}",
                },
                legend: {
                    show: true,
                    left: 0,
                    itemGap: 30,
                    data: [
                        {
                            name: "本月达标率",
                            icon: "rect",
                        },
                        {
                            name: "上月达标率",
                            icon: "rect",
                        },
                    ],
                    textStyle: {
                        fontSize: 16,
                    },
                },
                grid: {
                    left: "10px",
                    right: this.grid?.right,
                    bottom: this.grid?.bottom || "1%",
                    top: this.grid?.top || "10%",
                    containLabel: true,
                    show: false,
                },
                xAxis: {
                    type: "category",
                    data: this.lineData?.xData,
                    min: "dataMin",
                    max: "dataMax",
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            // color: '#ffffff'
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: '#2B9DF5',
                            width: 2,
                        },
                        symbol: ["none", "none"],
                    },
                    axisTick: { show: false },
                },
                yAxis: {
                    type: "value",
                    min: 0,
                    max: 100,
                    splitNumber: 5,
                    nameTextStyle: {
                        color: "#739AFF",
                        align: "right",
                        padding: [10, 0, 0, 0],
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: "dashed",
                            width: 2,
                            color: "#a2a2a2",
                        },
                    },
                    // y轴刻度
                    axisLabel: {
                        // 设置y轴数值为%
                        formatter: "{value} %",
                        interval: "auto",
                        textStyle: {
                            // color: '#eff2fb'
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            // color: '#2B9DF5',
                            width: 2,
                        },
                        symbol: ["none", "none"],
                    },
                    axisTick: { show: false },
                    show: true,
                },
                series: [
                    {
                        name: "达标率",
                        data: this.lineData?.value,
                        type: "bar",
                        smooth: true,
                        barWidth: 25,
                        itemStyle: {
                            color: "#43c8e0",
                        },
                        markLine: {
                            label: {
                                show: false,
                            },
                            lineStyle: {
                                type: "dashed",
                                color: "#4397e0",
                            },
                            emphasis: {
                                lineStyle: {
                                    type: "dashed",
                                    color: "#4397e0",
                                },
                            },
                            symbol: ["none", "none"],
                            data: [
                                [
                                    { type: "min", valueIndex: 0 },
                                    { type: "max", valueIndex: 0 },
                                ],
                            ],
                        },
                    },
                ],
            });
        },
    },
};
</script>

<style scoped></style>
